<template>
  <div>
    <GraphsMenu menu="importer"></GraphsMenu><br />
    <CCard>
    <CCardHeader>
      <slot name="header">
        Importador de Visitas
        <div class="card-header-actions" style="">{{$customer}}</div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="messages.length == 0">      
        <CCol md="12" style="text-align: center;">
          <CButton color="dark" size="lg" @click="showImporterModal()" style="margin: auto;">Importar Visitas</CButton>
        </CCol>   
      </CRow>
      <CRow v-if="messages.length > 0">       
        <CCol md="12" v-for="message in messages" style="pad">
          <CBadge color="info" v-if="message.type == 'info'" style="width: 50px;">Info</CBadge>
          <CBadge color="danger" v-if="message.type == 'error'" style="width: 50px;">Error</CBadge>
          <CBadge color="warning" v-if="message.type == 'warning'" style="width: 50px;">Aviso</CBadge>
          <CBadge color="success" v-if="message.type == 'success'" style="width: 50px;">Éxito</CBadge>
          <strong style="padding: 0px 5px">Fila {{message.row}}:</strong> {{message.message}}
        </CCol>      
      </CRow>
    </CCardBody>
  </CCard>
  <ImportingModal ref="importingModal" @imported="importProcess"></ImportingModal>
  </div>
</template>
<script>

import store from '../../store'
import GraphsMenu from './GraphsMenu.vue';

import ImportingModal from './modals/ImportingModal.vue';

export default {
  name: 'AdminImporter',
  components: {
    GraphsMenu,
    ImportingModal
  },
  data () {
    return {
      code: 'general',
      collaborators: [],
      messages: [],
      fields: [
          {key: 'position', label: ''},
          {key: 'name', label: 'Colaborador'},
          {key: 'total', label: 'Promedio'},
      ],
      customer_name: "",
      contract_name: "",
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_customer = localStorage.getItem("is_customer");

      if(is_customer == "false"){
        let is_kam = localStorage.getItem("is_kam");

        if(is_kam == "false"){
          window.location.href = "/#/home"; this.loaded(); return;
        }
        else{
          window.location.href = "/#/welcome"; this.loaded(); return;
        }
      }
      else{
        window.location.href = "/#/customer-graphs/general"; this.loaded(); return;
      }
    }

    this.loaded();
  },
  methods: {
    async showImporterModal() {
      this.loading();
      this.$refs.importingModal.show();
      this.loaded();
    },
    async importProcess(data) {
      this.loading();

      this.messages = data;

      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
